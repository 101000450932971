.container {
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;

  img {
    max-width: 150px;
    margin: 30px 0;
  }

  button {
    margin: 30px 0;
  }
}